export const onClientEntry = () => {
  // Add the FirstPromoter script
  const firstPromoterScript = document.createElement("script");
  firstPromoterScript.innerHTML = `
    (function(w){
      w.fpr=w.fpr||function(){
        w.fpr.q = w.fpr.q||[];
        w.fpr.q[arguments[0]=='set'?'unshift':'push'](arguments);
      };
    })(window);
    fpr("init", {cid:"07ltnnin"});
    fpr("click");
  `;
  document.body.appendChild(firstPromoterScript);

  // Add the FirstPromoter async script
  const firstPromoterAsyncScript = document.createElement("script");
  firstPromoterAsyncScript.src = "https://cdn.firstpromoter.com/fpr.js";
  firstPromoterAsyncScript.async = true;
  document.body.appendChild(firstPromoterAsyncScript);

  // Add the Chargebee script (if not already loaded)
  if (!window.Chargebee) {
    const chargebeeScript = document.createElement("script");
    chargebeeScript.src = "https://js.chargebee.com/v2/chargebee.js";
    chargebeeScript.onload = () => {
      window.Chargebee.init({
        site: "moonalgo",
        enableGTMTracking: true,
        enableFBQTracking: true,
      });

      // Add the additional script after Chargebee has loaded
      const cbAdditionalScripts = document.createElement("script");
      
      cbAdditionalScripts.innerHTML = `
       // code for fp affiliate
        var chargebeeTrackFunc=function(fprom) {
          var tid = fprom.tid;
          var chargebeeInstance;
          try{
            chargebeeInstance = Chargebee.getInstance(); 
          }
          catch(err){};
          if (tid && chargebeeInstance){ 
              var cart = chargebeeInstance.getCart();
              cart.setCustomer({cf_tid:tid}); 
          }else 
          if (tid){
            document.addEventListener("DOMContentLoaded",function(){chargebeeTrackFunc(fprom)});
          }
        };
        fpr('onReady', chargebeeTrackFunc);

        // Add FB Pixel Checkout Callbacks
        var cbInstance = Chargebee.getInstance();
        cbInstance.setCheckoutCallbacks(function(cart) {
          return {
            step: function(step) {
              fbq('trackCustom', step)
            }
          };
        });
      `;
      document.body.appendChild(cbAdditionalScripts);
    };

    document.body.appendChild(chargebeeScript);
  }
};
